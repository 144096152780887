import { FundingOption } from '@/globalTypes';

export const fundingOptionLabels: Record<FundingOption, string> = {
  Dividends: 'Dividends',
  Inheritance: 'Inheritance',
  Other: 'Other',
  Salary: 'Salary',
  SaleOfCompany: 'Sale of Company',
  SaleOfProperty: 'Sale of Property',
};
